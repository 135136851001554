import { useQuery } from 'react-query';
import { useEffect } from 'react';

// API
import { fetchUserSession } from '../../api/fetchUserSession';
import { fetchGaze } from '../../api/fetchGaze';
import { fetchUser, fetchUsers } from '../../api/fetchUser';
import { fetchFixations } from '../../api/fetchFixations';
import { fetchDynamicObjects } from '../../api/fetchDynamics';
import { fetchSettings } from '../../api/fetchSettings';

import { DASHBOARD_URL } from '../../env-constants';

// Components
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import Viewer from '../../components/Viewer/Viewer';

import './index.scss';

// Note, hooks must start with the word "use"
const useUserSession = () => {
  return useQuery('userSession', fetchUserSession);
};

const useUser = (userSessionData) => {
  return useQuery(
    ['user', userSessionData?.userId, userSessionData?.csrf],
    () => fetchUser(userSessionData?.userId, userSessionData?.csrf),
    {
      // Enable the query only if the user session data is available
      enabled: !!userSessionData
    }
  );
};

const useOrgUsers = (userSessionData, orgId) => {
  return useQuery(['orgUsers', orgId], () => fetchUsers(orgId), {
    // Enable the query only if the user session data is available
    enabled: !!userSessionData
  });
};

const useGaze = (userSessionData, sceneId, sdkSessionId) => {
  return useQuery(['gaze', sceneId, sdkSessionId], () => fetchGaze(sceneId, sdkSessionId), {
    // Enable the query only if the user session data is available
    enabled: !!userSessionData
  });
};

const useFixations = (userSessionData, sceneId, sdkSessionId) => {
  return useQuery(
    ['fixations', sceneId, sdkSessionId],
    () => fetchFixations(sceneId, sdkSessionId, userSessionData.csrf),
    {
      // Enable the query only if the user session data is available
      enabled: !!userSessionData
    }
  );
};

const useDynamicObjects = (userSessionData, sceneId, sdkSessionId) => {
  return useQuery(
    ['dynamicObjects', sceneId, sdkSessionId],
    () => fetchDynamicObjects(sceneId, sdkSessionId, userSessionData.csrf),
    {
      // Enable the query only if the user session data is available
      enabled: !!userSessionData
    }
  );
};

const useSettings = (userSessionData, sceneId) => {
  return useQuery(['settings', sceneId], () => fetchSettings(sceneId, userSessionData.csrf), {
    // Enable the query only if the user session data is available
    enabled: !!userSessionData
  });
};

const Main = () => {
  // First pass of query params to get version
  const location = window.location;
  const queryParameters = new URLSearchParams(location.search);

  const sceneId = location.pathname.split('/')[1]; // for a url like: https://app-local.c3ddev.com/scene/64fa63f9-72b4-4a76-aa94-199b0d489e85?version=2
  const version = queryParameters.get('version');
  const sdkSessionId = '8'; // Hardcoded for now

  // Query for getting userId and crsf token
  const {
    data: userSessionData
    // isLoading, isError // Uncomment if need to handle loading and error states
  } = useUserSession();

  // Dependant queries, will only run if userId and crsf token is available in userSessionData
  const { data: userData } = useUser(userSessionData);
  const { data: orgUserData } = useOrgUsers(userSessionData, 12);
  const { data: gazeData } = useGaze(userSessionData, sceneId, sdkSessionId);
  const { data: fixationData } = useFixations(userSessionData, sceneId, sdkSessionId);
  const { data: dynamicObjectsData } = useDynamicObjects(userSessionData, sceneId, sdkSessionId);
  const { data: settingsData } = useSettings(userSessionData, sceneId);

  // Use effect hooks to log data as they change
  useEffect(() => {
    if (userSessionData) {
      console.log('userSessionData', userSessionData);
    }
  }, [userSessionData]);

  useEffect(() => {
    if (userData) {
      console.log('userData', userData);
    }
  }, [userData]);

  useEffect(() => {
    if (orgUserData) {
      console.log('orgUserData', orgUserData);
    }
  }, [orgUserData]);

  useEffect(() => {
    if (gazeData) {
      console.log('gazeData', gazeData);
    }
  }, [gazeData]);

  useEffect(() => {
    if (fixationData) {
      console.log('fixationData', fixationData);
    }
  }, [fixationData]);

  useEffect(() => {
    if (dynamicObjectsData) {
      console.log('dynamicObjectsData', dynamicObjectsData);
    }
  }, [dynamicObjectsData]);

  useEffect(() => {
    if (settingsData) {
      console.log('settingsData', settingsData);
    }
  }, [settingsData]);

  const baseClassName = 'Main';

  // If no sceneId or sdkSessionId is available, show message to enter valid entries for each respectively
  if (!version) {
    return (
      <div className={`${baseClassName}__container`}>
        <div className={`${baseClassName}__content`}>
          <h2>
            Please enter a valid Scene ID or return to the{' '}
            <a href={DASHBOARD_URL} className={`${baseClassName}__link`} target="_blank" rel="noreferrer">
              dashboard
            </a>
            .
          </h2>
        </div>
      </div>
    );
  }

  // If user session data is not available (because user is not logged on), show not logged on screen
  if (!userSessionData) {
    const redirectLocation = encodeURIComponent(window.location.pathname + window.location.search);
    const url = `${DASHBOARD_URL}/login?redirectLocation=${redirectLocation}`;

    return (
      <div className={`${baseClassName}__container`}>
        <div className={`${baseClassName}__content`}>
          <h2>
            Oops! <br />
            <br />
            It seems you are not signed in.
            <br />
            <br />
            Go to the{' '}
            <a className={`${baseClassName}__contact`} href={url} target="_blank" rel="noreferrer">
              dashboard
            </a>{' '}
            to gain access to this scene.
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="Main">
      <Navbar></Navbar>
      <Viewer></Viewer>
      <Footer></Footer>
    </div>
  );
};

export default Main;
