import * as THREE from "three";

export default class viewGL {
  constructor(canvasRef) {
    console.log("canvas " + canvasRef);
    console.log(canvasRef.width);
    console.log(canvasRef.height);

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      //  canvasRef.width / canvasRef.height,
      0.1,
      1000
    );
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();

    this.renderer = new THREE.WebGLRenderer({
      canvas: canvasRef,
      antialias: true,
    });
    this.renderer.setSize(window.innerWidth, window.innerHeight, false);

    this.renderer.render(this.scene, this.camera);

    // Create meshes, materials, etc.
    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshLambertMaterial({ color: 0x0049ef4 });
    this.light = new THREE.DirectionalLight(0xffffff, 5);
    this.cube = new THREE.Mesh(geometry, material);
    this.camera.position.z = 5;
    this.scene.add(this.light);
    this.scene.add(this.cube);
    this.light.position.x = 1;
    this.light.position.z = 1;

    this.update();
  }

  // ******************* PUBLIC EVENTS ******************* //
  updateValue(value) {
    // Whatever you need to do with React props
  }

  onMouseMove() {
    // Mouse moves
  }

  onWindowResize(vpW, vpH) {
    this.renderer.setSize(vpW, vpH, false);
    this.camera.aspect = vpW / vpH;
    this.camera.updateProjectionMatrix();
  }

  // ******************* RENDER LOOP ******************* //
  update() {
    this.cube.rotation.x += 0.001;
    this.cube.rotation.z += 0.001;
    this.renderer.render(this.scene, this.camera);

    requestAnimationFrame(this.update.bind(this));
  }
}
