export const VIEWER_URL = 'https://viewer2.c3ddev.com';
export const DASHBOARD_URL = 'https://app.c3ddev.com';

const API = 'api.c3ddev.com'; // For running dev data locally
//const API_URL: 'api.cognitive3d.com'; // For running prod data locally

// T-7358 DELETE ONCE ALL API CALLS ARE REFACTORED
let apiUrl = API + '/v0';
if (!apiUrl.startsWith('http://') && !apiUrl.startsWith('https://')) {
  apiUrl = 'https://' + apiUrl;
}
export const API_URL = apiUrl;

let dashboardUrl = DASHBOARD_URL;
if (!dashboardUrl.startsWith('http://') && !dashboardUrl.startsWith('https://')) {
  dashboardUrl = 'https://' + dashboardUrl;
}
export const DOMAIN = dashboardUrl;
