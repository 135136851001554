import Header from '../Header/Header';

import './Navbar.scss';

// Convert to a class based component
const Navbar = () => {
  return (
    <div className="navbar">
      <ul>
        <li>
          <Header></Header>
        </li>
        <li>
          <div>Scene</div>
        </li>
        <li>
          <span>Session</span>
        </li>
        <li>
          <span>Gaze</span>
        </li>
        <li>
          <span>Path</span>
        </li>
        <li>
          <span>Event</span>
        </li>
        <li>
          <span>Advanced</span>
        </li>
        <li>
          <span>Help</span>
        </li>
        <li>
          <span>Share</span>
        </li>
        <li>
          <span>Screenshot</span>
        </li>
        <li>
          <span>Dashboard</span>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
