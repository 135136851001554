import React from 'react';
import './Header.scss';

// Convert to a class based component

const Header = () => {
  return (
    <div className='header'>
      <h3>SE3</h3>
    </div>
  );
};

export default Header;
