import axios from 'axios';
import { API_URL } from '../env-constants';

export const fetchSettings = async (sceneId, csrfToken) => {
  const response = await axios.get(`${API_URL}/versions/${sceneId}/settings`, {
    withCredentials: true,
    headers: { 'c3d-csrf': csrfToken }
  });
  return response.data;
};

export const saveSettings = async (sceneId, setting, csrfToken) => {
  const response = await axios.put(`${API_URL}/versions/${sceneId}/settings`, setting, {
    withCredentials: true,
    headers: { 'c3d-csrf': csrfToken }
  });
  return response.data;
};
