import axios from 'axios';
import { API_URL } from '../env-constants';

export const fetchUser = async (userId, csrfToken) => {
  const response = await axios.get(`${API_URL}/users/${userId}`, {
    withCredentials: true,
    headers: { 'c3d-csrf': csrfToken }
  });
  return response.data;
};

export const fetchUsers = async (orgId) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/users`, {
    withCredentials: true
  });
  return response.data;
};
