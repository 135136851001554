import axios from 'axios';
import { API_URL } from '../env-constants';

export const fetchFixations = async (sceneId, sdkSessionId, csrfToken) => {
  const response = await axios.get(`${API_URL}/versions/${sceneId}/fixations/${encodeURI(sdkSessionId)}`, {
    withCredentials: true,
    headers: { 'c3d-csrf': csrfToken }
  });
  return response.data;
};
